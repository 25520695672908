import React, { useState, useRef, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useIntl } from 'react-intl'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { ConfigContext, RacesContext } from '../../components/AppContexts'

const useStyles = makeStyles(theme => ({
	root: {
		position: 'static'
	},
	rtMenu: {
		padding: 5,
		marginTop: 15,
		width: '100%',
		overflowX: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	},
	rtButton: {
		//height: '30px'
		//		backgroundColor: theme.palette.secondary.main,
		//	color: 'white',
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: '1em'
		//padding: 25
	},
	rtInactive: {
		textDecoration: 'line-through'
	},
	disabledButton: {
		//color: '#aaaaff'
		//backgroundColor: '#ffffff'
	},
	rootMessage: {
		width: '100%',
		//padding: '16px',
		textAlign: 'center'
	},
	left: {},
	middle: {},
	right: {
		textAlign: 'right'
	},
	headerText: {
		fontWeight: 400,
		minWidth: 0,
		fontSize: 10,
		textAlign: 'center',
		color: 'red'
	},
	'@keyframes blinker': {
		from: { opacity: 1 },
		to: { opacity: 0.5 }
	},
	blink: {
		animationDuration: '2s',
		animationName: '$blinker',
		animationIterationCount: 'infinite',
		animationDirection: 'alternate',
		animationTimingFunction: 'ease-in-out'
	}
}))

const RTS_LIST_QUERY = gql`
	{
		rtsList {
			id
			name
			nameText
			race {
				id
				name
			}
			group {
				id
				name
				isOnline
				message
			}
			isActive
			stagesNames
			stagesCount
		}
	}
`

function RaceLine({ racesList, selectedRace, onChange }) {
	const classes = useStyles()

	return (
		<React.Fragment>
			<ToggleButtonGroup
				variant="outlined"
				size="small"
				exclusive={true}
				onChange={(event, value) => {
					if (value) onChange(value)
				}}
				value={selectedRace}
				className={classes.rtMenu}
				//style={{ height: '20px' }}
			>
				{racesList.map(race => {
					return (
						<ToggleButton key={race.name} value={race.id} className={classes.rtButton}>
							{race.name}
						</ToggleButton>
					)
				})}
			</ToggleButtonGroup>
		</React.Fragment>
	)
}

function RTSLine({ rtsList, selectedRT, onChange, messageRT }) {
	const classes = useStyles()
	const intl = useIntl()
	const rtMenuRef = useRef(null)
	const [scrollLeftValue, setScrollLeftValue] = useState(Number(localStorage.getItem('scrollLeft')) || 0)

	React.useEffect(
		() => {
			rtMenuRef.current.scrollLeft = scrollLeftValue
		},
		[scrollLeftValue]
	)

	const handleChange = value => {
		const scrollValue = rtMenuRef.current.scrollLeft
		localStorage.setItem('scrollLeft', scrollValue)
		setScrollLeftValue(scrollValue)
		onChange(value)
	}

	return (
		<React.Fragment>
			<ToggleButtonGroup
				variant="outlined"
				size="small"
				exclusive={true}
				onChange={(event, value) => {
					if (value) {
						handleChange(value)
					}
				}}
				value={selectedRT}
				className={classes.rtMenu}
				ref={rtMenuRef}
			>
				{rtsList.map(rt => {
					const groupID = rt.group.id
					const inactiveClass = rt.isActive === true ? '' : classes.rtInactive
					const prefRT = intl.formatMessage({ id: rt.name.substring(0, 2).toLowerCase() })

					return (
						<ToggleButton
							key={groupID}
							value={groupID}
							disabled={!rt.group.isOnline}
							className={`${classes.rtButton} ${false && inactiveClass}`}
							//classes={{ disabled: classes.disabledButton }}
						>
							{`${prefRT}${rt.name.substring(2)}`}
						</ToggleButton>
					)
				})}
			</ToggleButtonGroup>
			<Grid container spacing={2} className={classes.rootMessage}>
				<Grid item xs={12} container>
					<Grid item xs={2} className={classes.left} />
					<Grid item xs={8} className={classes.middle}>
						{true &&
							<Typography noWrap className={`${classes.headerText} ${false && classes.blink}`}>
								{messageRT}
							</Typography>}
					</Grid>
					<Grid item xs={2} className={classes.right} />
				</Grid>
			</Grid>
		</React.Fragment>
	)
}

function MenuRT({ setRankingSelectedRT, setRankingStages, rallyeName }) {
	const config = useContext(ConfigContext)
	const racesList = useContext(RacesContext)

	const isMix = config.mixDisplayRaces
	const [selectedRace, setSelectedRace] = useState(localStorage.getItem('selectedRace') || null)
	const [selectedRT, setSelectedRT] = useState(localStorage.getItem(`selectedRT${selectedRace}`) || null)
	const [messageRT, setMessageRT] = useState(null)

	let rtsList = []
	const { loading: rtsListLoading, error: rtsListError, data: rtsListData } = useQuery(RTS_LIST_QUERY, {
		pollInterval: config.pollInterval
	})

	React.useEffect(
		() => {
			if (selectedRT !== null) {
				localStorage.setItem(`selectedRT${selectedRace}`, selectedRT)
				setRankingSelectedRT(selectedRT)
			}
			if (selectedRace !== null) localStorage.setItem('selectedRace', selectedRace)
		},
		[selectedRT, selectedRace, setRankingSelectedRT]
	)
	React.useEffect(
		() => {
			if (rtsList.length) {
				const filteredList = rtsList.filter(rt => rt.group.id === selectedRT)
				const stageNames = filteredList.length ? filteredList[0].stagesNames : null //TODO: check si nécessaire
				setRankingStages(stageNames)
				const message = filteredList.length ? filteredList[0].group.message : null
				setMessageRT(message)
			}
		},
		[rtsList, setRankingStages, selectedRT, setMessageRT]
	)
	if (rtsListLoading) return 'Loading...'
	if (rtsListError) return `Error! ${rtsListError && rtsListError.message}`
	rtsList = isMix === true ? rtsListData.rtsList : rtsListData.rtsList.filter(rt => rt.race.id === selectedRace)
	/*	if (
		selectedRT === null &&
		(isMix === true || selectedRace !== null)) //setSelectedRT(rtsList[0].group.id)
	*/
	return (
		<React.Fragment>
			{isMix === false &&
				<RaceLine racesList={racesList} selectedRace={selectedRace} onChange={setSelectedRace} />}
			<RTSLine rtsList={rtsList} selectedRT={selectedRT} onChange={setSelectedRT} messageRT={messageRT} />
		</React.Fragment>
	)
}
export default MenuRT

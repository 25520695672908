import en from './en-GB'
import fr from './fr-FR'
import es from './es-ES'
import ar from './ar-SA'

export default {
	...en,
	...fr,
	...es,
	...ar
}

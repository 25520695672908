import React, { useContext } from 'react'
import { ConfigContext } from '../AppContexts'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, FormControl, Select, MenuItem, OutlinedInput } from '@material-ui/core'
import { Image } from 'cloudinary-react'

const useStyles = makeStyles({
	root: {
		width: '100%',
		//padding: '16px',
		textAlign: 'center'
	},
	left: {},
	middle: {},
	right: {
		textAlign: 'right'
	},
	headerText: {
		fontWeight: 400,
		minWidth: 0,
		fontSize: 10,
		textAlign: 'center',
		color: '#ffffff'
	},
	icon: {
		fill: '#ffffff'
	}
})

const useOutlinedInputStyles = makeStyles(theme => ({
	root: {
		'& $notchedOutline': {
			borderColor: '#ffffff'
		},
		'&:hover $notchedOutline': {
			borderColor: '#ffffff'
		},
		'&$focused $notchedOutline': {
			borderColor: '#ffffff'
		}
	},
	focused: {},
	notchedOutline: {}
}))

const LocaleMenu = ({ locale, changeLocale, LOCALES }) => {
	const classes = useStyles()
	const outlinedInputClasses = useOutlinedInputStyles()
	const options = [
		{ value: LOCALES.ENGLISH, label: 'English', flag: 'GB', text: 'ENG' },
		{ value: LOCALES.FRENCH, label: 'French', flag: 'FR', text: 'FR' },
		{ value: LOCALES.SPANISH, label: 'Spanish', flag: 'ES', text: 'ES' },
		{ value: LOCALES.ARABIC, label: 'Arabic', flag: 'SA', text: 'عرب' }
	]

	return (
		<FormControl variant="outlined" size="small">
			<Select
				labelId="locale-label"
				id="locale-select"
				value={locale}
				onChange={e => {
					e.preventDefault()
					changeLocale(e.target.value)
				}}
				input={
					<OutlinedInput
						//labelWidth={labelWidth}
						name="locale"
						id="outlined-locale-simple"
						classes={outlinedInputClasses}
					/>
				}
				inputProps={{
					classes: {
						icon: classes.icon
					}
				}}
			>
				{options.map(option =>
					<MenuItem key={option.flag} value={option.value}>
						<img src={`https://www.countryflags.io/${option.flag}/shiny/16.png`} alt={option.label} />
					</MenuItem>
				)}
			</Select>
		</FormControl>
	)
}

const AppHeader = ({ raceName, raceText, locale, changeLocale, LOCALES }) => {
	const config = useContext(ConfigContext)
	const classes = useStyles()
	const logoPath = 'races/' + raceName + '.png'

	return (
		<Grid container spacing={2} className={classes.root}>
			<Grid item xs={12} container>
				<Grid item xs={2} className={classes.left} />
				<Grid item xs={8} className={classes.middle}>
					{' '}<Image publicId={logoPath} width="90" height="40" />{' '}
					{config.raceNameHeader &&
						<Typography noWrap className={classes.headerText}>
							{raceText}
						</Typography>}
				</Grid>
				<Grid item xs={2} className={classes.right}>
					<LocaleMenu LOCALES={LOCALES} locale={locale} changeLocale={changeLocale} />
				</Grid>
			</Grid>
		</Grid>
	)
}

export default AppHeader

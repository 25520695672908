import React, { useContext } from 'react'
import { useTheme } from '@material-ui/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTable, useFlexLayout } from 'react-table'
import { useSticky } from 'react-table-sticky'
import { OptionsContext } from '../AppContexts'
import './MUITable.scss'

function MUITable({ columns, data, type, isMix = false }) {
	let barsHeight
	switch (type) {
		case 'rankingOV':
		case 'rankingRT':
			barsHeight = isMix ? 128 : 170
			break
		case 'departures':
			barsHeight = 75
			break
		case 'startList':
			barsHeight = 0
			break
		default:
			break
	}

	const useStyles = makeStyles(theme => ({
		tableContainer: {
			height: `calc(100vh - ${barsHeight}px - 32px)`
		},
		row: {
			'&:nth-of-type(odd)': {
				'& td': {
					backgroundColor: theme.palette.tableBackgroundOdd
				}
			},
			'&:nth-of-type(even)': {
				'& td': {
					backgroundColor: theme.palette.tableBackgroundEven
				}
			}
		}
	}))
	const classes = useStyles()

	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data
		},
		useFlexLayout,
		useSticky
	)
	const theme = useTheme()
	const background = theme.palette.tableBackground
	const { carNumber } = useContext(OptionsContext)

	return (
		<div className="table-root">
			<TableContainer className={classes.tableContainer}>
				<Table {...getTableProps()} className="table sticky {classes.odd}">
					<TableHead className="header">
						{headerGroups.map(headerGroup =>
							<TableRow {...headerGroup.getHeaderGroupProps()} className="trh">
								{headerGroup.headers.map(column =>
									<TableCell {...column.getHeaderProps()} className="th" bgcolor={background}>
										{column.render('Header')}
									</TableCell>
								)}
							</TableRow>
						)}
					</TableHead>
					<TableBody className="body">
						{rows.map((row, i) => {
							prepareRow(row)
							const trColor = row.values.raceNumber === carNumber ? 'trFluo' : 'tr'
							return (
								<TableRow {...row.getRowProps()} className={`${trColor} ${classes.row}`}>
									{row.cells.map(cell => {
										return (
											<TableCell {...cell.getCellProps()} className="td" bgcolor={background}>
												{cell.render('Cell')}
											</TableCell>
										)
									})}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

export default MUITable

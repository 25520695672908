import React, { useState, useContext, useEffect } from 'react'
import { ConfigContext, OptionsContext } from './components/AppContexts'
import { Router, Location } from '@reach/router'
import { IntlProvider } from 'react-intl'
import { LOCALES } from './i18n/locales'
import messages from './i18n/messages'
import { createMuiTheme, ThemeProvider, makeStyles, StylesProvider, jssPreset } from '@material-ui/core/styles'
import { CssBaseline, Toolbar } from '@material-ui/core'
import CookieConsent from 'react-cookie-consent'
import { create } from 'jss'
import rtl from 'jss-rtl'
import Layout, {
	Root,
	getHeader,
	getDrawerSidebar,
	getSidebarTrigger,
	getSidebarContent,
	getCollapseBtn,
	getContent,
	getFooter,
	getStandardScheme
} from '@mui-treasury/layout'

import { NavContent, AppHeader, AppFooter } from './components/MUILayout'
import { Departures, RankingOV, RankingRT, Pdf, StartList, Settings } from './views'
import styled from 'styled-components'

const Header = getHeader(styled)
const DrawerSidebar = getDrawerSidebar(styled)
const SidebarTrigger = getSidebarTrigger(styled)
const SidebarContent = getSidebarContent(styled)
const CollapseBtn = getCollapseBtn(styled)
const Content = getContent(styled)
const Footer = getFooter(styled)
const standardScheme = getStandardScheme()
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function App() {
	const config = useContext(ConfigContext)

	const [locale, setLocale] = useState(
		localStorage.getItem('locale') ? localStorage.getItem('locale') : LOCALES.ENGLISH
	)
	const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('isDarkMode') === 'true')
	const [carNumber, setCarNumber] = useState(
		localStorage.getItem('carNumber') ? Number(localStorage.getItem('carNumber')) : ''
	)
	const carContext = { carNumber, setCarNumber }
	const [data, setData] = useState({
		header: true,
		nav: true,
		content: true,
		footer: config.name === 'DKC2021' ? false : true
	})

	React.useEffect(
		() => {
			localStorage.setItem('isDarkMode', isDarkMode)
		},
		[isDarkMode]
	)
	React.useEffect(
		() => {
			localStorage.setItem('carNumber', carNumber)
		},
		[carNumber]
	)
	React.useEffect(
		() => {
			localStorage.setItem('locale', locale)
			document.body.dir = locale === 'ar-SA' ? 'rtl' : 'ltr'
		},
		[locale]
	)

	const raceName = config.name
	const raceText = config.nameText

	const theme = createMuiTheme({
		direction: locale === 'ar-SA' ? 'rtl' : 'ltr',
		palette: {
			type: isDarkMode ? 'dark' : 'light',
			primary: {
				main: config.primaryColor ? config.primaryColor : isDarkMode ? '#ffffff' : '#757575'
			},
			secondary: {
				main: config.secondaryColor ? config.secondaryColor : isDarkMode ? '#ffffff' : '#757575'
			}, //contrastThreshold: 8,
			tableBackground: isDarkMode ? '#424242' : '#fff',
			tableBackgroundOdd: isDarkMode ? '#757575' : '#e9e9e9',
			tableBackgroundHeader: isDarkMode ? '#424242' : '#fff'
		},
		typography: {
			fontSize: 13 //NOTE: all application font-size
		},
		overrides: {
			MuiDrawer: {
				overflow: 'hidden'
			}
		}
	})

	const useStyles = makeStyles(theme => ({
		root: {
			height: '100%'
		},
		header: {
			padding: 2,
			backgroundColor: config.primaryColor ? config.primaryColor : isDarkMode ? '#212121' : '#f5f5f5'
		},
		content: {
			height: `calc(100vh - 64px)`
		},
		footer: {
			//marginTop: '-15px',
			//marginBottom: 25,
			//bottom: 0
		}
	}))
	const classes = useStyles()

	standardScheme.enableAutoCollapse('primarySidebar', 'xl')
	standardScheme.configureEdgeSidebar(builder => {
		builder.update('primarySidebar', config => {
			config.sm.collapsedWidth = 66
			config.xs.width = 250
			config.xs.headerMagnetEnabled = true
		})
	})

	const changeLocale = lang => {
		setLocale(lang)
	}

	return (
		<OptionsContext.Provider value={carContext}>
			<IntlProvider locale={locale} messages={messages[locale]}>
				<StylesProvider jss={jss}>
					<Root scheme={standardScheme} className={classes.root}>
						{({ state: { sidebar }, setOpen }) =>
							<Location>
								{({ location }) =>
									<ThemeProvider theme={theme}>
										<CssBaseline />
										<Header className={classes.header}>
											<Toolbar>
												<SidebarTrigger sidebarId="primarySidebar" />
												{data.header &&
													<AppHeader
														raceName={raceName}
														raceText={raceText}
														locale={locale}
														changeLocale={changeLocale}
														LOCALES={LOCALES}
													/>}
											</Toolbar>
										</Header>
										<DrawerSidebar sidebarId="primarySidebar">
											<SidebarContent>
												{data.nav &&
													<NavContent
														raceName={raceName}
														location={location}
														sidebar={sidebar}
														setOpen={setOpen}
														collapsed={sidebar.primarySidebar.collapsed}
													/>}
											</SidebarContent>
											<CollapseBtn />
										</DrawerSidebar>
										<Content className={classes.content}>
											{data.content &&
												<Router>
													<RankingRT path="/stages" default />
													<RankingOV path="/overall" />
													<Departures path="/departures" />
													<StartList path="/startlist" />
													<Pdf path="/pdf" />
													<Settings
														path="/settings"
														themeChange={setIsDarkMode}
														isDarkMode={isDarkMode}
													/>
												</Router>}
										</Content>
										<Footer className={classes.footer}>
											{data.footer && <AppFooter />}
										</Footer>
										<CookieConsent
											style={{
												fontSize: '10px',
												textAlign: 'center'
											}}
										>
											This website uses essential functional cookies to enhance the user
											experience.
										</CookieConsent>
									</ThemeProvider>}
							</Location>}
					</Root>
				</StylesProvider>
			</IntlProvider>
		</OptionsContext.Provider>
	)
}

export default App

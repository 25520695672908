import axios from 'axios'

const raceName = process.env.REACT_APP_RACENAME.replace('jbtclive/', '').toUpperCase()
const uri =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:4100'
		: 'https://' + raceName + '-server-three.vercel.app'

export const getTreePDF = axios.create({
	baseURL: `${uri}/getTreePDF`
})

import React, { useState, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { ConfigContext } from '../../components/AppContexts'

const useStyles = makeStyles(theme => ({
	root: {
		position: 'static'
	},
	ovMenu: {
		padding: 2,
		marginTop: 10,
		width: '100%',
		overflowX: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	},
	ovButton: {
		borderRadius: '1em',
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		padding: 2
		//height: '48px'
		//border-radius: 24px;
		//padding: 25
	}
}))

const OVS_LIST_QUERY = gql`
	{
		ovsList {
			id
			name
			dayNum
			isOnline
			isJokers
			isJokersSpecials
			race {
				id
				name
			}
			rts {
				id
				name
			}
			chs {
				id
				name
			}
		}
	}
`
function MenuOV({
	setRankingSelectedOV,
	setStagesRT,
	setStagesCHS,
	setRankingDay,
	setRankingJokers,
	setRankingJokersSpecials,
	refetch,
	rankingDay
	//onlineStatus
}) {
	const classes = useStyles()
	const config = useContext(ConfigContext)
	const intl = useIntl()
	const [selectedOV, setSelectedOV] = useState(localStorage.getItem('selectedOV') || null)
	const [race, setRace] = useState(null)
	let ovsList = []
	let msgDay
	switch (config.nameDay) {
		case 'Day':
			msgDay = 'day'
			break
		case 'Leg':
			msgDay = 'leg'
			break
		case 'Stage':
			msgDay = 'stage'
			break
		default:
			msgDay = 'day'
			break
	}
	const { loading: ovsListLoading, error: ovsListError, data: ovsListData } = useQuery(OVS_LIST_QUERY, {
		pollInterval: config.pollInterval
	})
	React.useEffect(
		() => {
			if (selectedOV !== null) {
				localStorage.setItem('selectedOV', selectedOV)
				setRankingSelectedOV(selectedOV)
			}
		},
		[selectedOV, setRankingSelectedOV]
	)
	React.useEffect(
		() => {
			if (ovsListData) {
				const filteredList = ovsListData.ovsList.filter(ov => ov.id === selectedOV)
				const stagesRT = filteredList.length ? filteredList[0].rts : []
				const stagesCHS = filteredList.length ? filteredList[0].chs : []
				const rankingDay = filteredList.length ? filteredList[0].dayNum : null
				const rankingJokers = filteredList.length ? filteredList[0].isJokers : null
				const rankingJokersSpecials = filteredList.length ? filteredList[0].isJokersSpecials : null
				const race = filteredList.length ? filteredList[0].race.id : null
				setStagesRT(stagesRT)
				setStagesCHS(stagesCHS)
				setRankingDay(rankingDay)
				setRankingJokers(rankingJokers)
				setRankingJokersSpecials(rankingJokersSpecials)
				setRace(race)
			}
		},
		[ovsListData, selectedOV, setStagesRT, setStagesCHS, setRankingDay, setRankingJokers, setRankingJokersSpecials] //NOTE: add ovsList to dependencies cause infinite render (needs investigate)
	)
	if (ovsListLoading) return 'Loading...'
	if (ovsListError) return `Error! ${ovsListError && ovsListError.message}`
	if (ovsListData) ovsList = ovsListData.ovsList
	//if (selectedOV === null) setSelectedOV(ovsList[0].id)
	//const online = ovsList.length ? ovsList.filter(ov => ov.isOnline === true).length : 0
	//onlineStatus.current = online
	const handleChange = value => {
		setSelectedOV(value)
	}
	return (
		<ToggleButtonGroup
			variant="outlined"
			size="small"
			exclusive={true}
			onChange={(event, value) => {
				if (value) {
					handleChange(value)
				}
			}}
			value={selectedOV}
			className={classes.ovMenu}
		>
			{ovsList.map(ov => {
				const ovID = ov.id
				return (
					<ToggleButton key={ovID} value={ovID} disabled={!ov.isOnline} className={classes.ovButton}>
						{ov.name.replace(msgDay.toUpperCase(), intl.formatMessage({ id: msgDay }))}
					</ToggleButton>
				)
			})}
			{/*	<IconButton
				size="small"
				onClick={() => {
					refetch({
						variables: { selectedOV: selectedOV }
					})
				}}
			>
				<Refresh />
			</IconButton>*/}
		</ToggleButtonGroup>
	)
}

export default MenuOV

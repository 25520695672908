import React, { useContext } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { ConfigContext } from '../../components/AppContexts'

const FooterRT = ({ viewTypeRT, setViewTypeRT, rtPrefix }) => {
	const config = useContext(ConfigContext)
	const intl = useIntl()

	return (
		<Tabs
			value={viewTypeRT}
			onChange={(event, value) => {
				if (value !== null) setViewTypeRT(value)
			}}
			indicatorColor="secondary"
			//textColor="primary"
			variant="fullWidth"
			aria-label="full width tabs example"
		>
			<Tab label={intl.formatMessage({ id: 'rzSummary' })} value="summary" />
			<Tab label={intl.formatMessage({ id: 'rzDetailed' })} value="detailed" />
			{config.isPartialOV && <Tab label={`${rtPrefix} OVERALL`} value="rankingRTOV" />}
		</Tabs>
	)
}

FooterRT.propTypes = {}
FooterRT.defaultProps = {}

export default FooterRT

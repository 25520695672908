import React from 'react'
import { Typography, Link } from '@material-ui/core'
import { Image } from 'cloudinary-react'

const logoPath = 'internal/logoJBTC.png'

const AppFooter = () =>
	<div style={{ margin: 'auto', textAlign: 'center', padding: 12 }}>
		<Typography variant="caption" align={'center'}>
			Powered by:{' '}
			<Link href="https://jbtimeconcept.be" target="_blank">
				<Image publicId={logoPath} width="60" height="25" />
			</Link>
		</Typography>
	</div>

AppFooter.propTypes = {}
AppFooter.defaultProps = {}

export default AppFooter

import React, { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { FormattedMessage, useIntl } from 'react-intl'
import { Paper, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ConfigContext } from '../components/AppContexts'
import { MenuOV, FooterOV } from '../components/SubMenus'
import { MUITable } from '../components/MUITable'
import { pointsToTime } from '../utils/timeConverter'
import { Image } from 'cloudinary-react'

const useStyles = makeStyles(theme => ({
	container: {
		height: '100%'
	},
	menu: {
		height: '64px'
	},
	table: { height: 'calc(100vh - 180px)' },
	footer: {
		height: '40px'
	},
	content: {
		height: 'auto'
	},
	total: {
		//color: theme.palette.primary.main
		//textAlign: 'center'
		//paddingRight: 'calc(100%-20px)'
	},
	numberSpan: {
		display: 'inline-block',
		textAlign: 'right',
		width: '40px'
	},
	typography: {
		flexGrow: 1,
		paddingTop: 2
		//align: 'center'
	}
}))

const RANKING_OV_QUERY = gql`
	query rankingOVQuery($rankingID: ID) {
		rankingOV(rankingID: $rankingID) {
			position
			team {
				raceNumber
				teamName
				isRookie
			}
			car {
				model
				classDisplay
				groupDisplay
				coefficientDisplay
			}
			avg
			classPos
			groupPos
			resultsRT
			resultsCHS
			totalCH
			totalSC
			totalCP
			totalPen
			totalPointsDay
			pointsBefore
			pointsJokers
			pointsJokersSpecials
			totalPointsOV
			difPrevious
			difFirst
			isRetired
		}
	}
`

function RankingOV() {
	const classes = useStyles()
	const config = useContext(ConfigContext)
	const intl = useIntl()
	const [selectedOV, setSelectedOV] = useState(null)
	const [stagesRT, setStagesRT] = useState([])
	const [stagesCHS, setStagesCHS] = useState([])
	const [rankingOV, setRankingOV] = useState([])
	const [rankingDay, setRankingDay] = useState(null)
	const [rankingJokers, setRankingJokers] = useState(null)
	const [rankingJokersSpecials, setRankingJokersSpecials] = useState(null)
	const [viewTypeOV, setViewTypeOV] = useState(localStorage.getItem('viewTypeOV') || 'summary')
	//const onlineStatus = React.useRef(null)
	let msgDay
	switch (config.nameDay) {
		case 'Day':
			msgDay = 'day'
			break
		case 'Leg':
			msgDay = 'leg'
			break
		case 'Stage':
			msgDay = 'stage'
			break
		default:
			msgDay = 'day'
			break
	}
	useEffect(
		() => {
			localStorage.setItem('viewTypeOV', viewTypeOV)
		},
		[viewTypeOV]
	)

	const {
		loading: rankingOVLoading,
		error: rankingOVError,
		data: rankingOVData,
		refetch
	} = useQuery(RANKING_OV_QUERY, {
		skip: !selectedOV,
		pollInterval: config.pollInterval,
		variables: { rankingID: selectedOV }
	})

	useEffect(
		() => {
			if (!rankingOVLoading && rankingOVData) {
				setRankingOV(rankingOVData.rankingOV)
			}
		},
		[rankingOVLoading, rankingOVData]
	)

	if (rankingOVLoading) return 'Loading...'
	if (rankingOVError) return `Error! ${rankingOVError && rankingOVError.message}`

	const columnsRankingOV = [
		{
			Header: <FormattedMessage id="position" />,
			accessor: 'position',
			maxWidth: 30,
			sticky: 'left'
		},
		{ Header: '#', accessor: 'team.raceNumber', id: 'raceNumber', maxWidth: 30, sticky: 'left' },
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'team.teamName',
			Cell: ({ cell }) => {
				return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
			},
			minWidth: 175,
			maxWidth: 200
		}
	]

	if (viewTypeOV === 'detailed') {
		columnsRankingOV.push({
			Header: config.nameCar === 'Vehicle' ? <FormattedMessage id="vehicle" /> : <FormattedMessage id="car" />,
			accessor: 'car.model',
			id: 'car',
			minWidth: 200,
			maxWidth: 220
		})

		if (config.isDisplayAVG === true) {
			columnsRankingOV.push({ Header: <FormattedMessage id="avg" />, accessor: 'avg', maxWidth: 60 })
		}
		if (config.isDisplayGroup === true)
			columnsRankingOV.push(
				{
					Header: (
						<Tooltip title={intl.formatMessage({ id: 'TTgroup' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'groupShort' })}
							</Typography>
						</Tooltip>
					),
					accessor: 'car.groupDisplay',
					maxWidth: 50
				},
				{
					Header: (
						<Tooltip title={intl.formatMessage({ id: 'TTgroupPos' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'position' })}
							</Typography>
						</Tooltip>
					),
					accessor: 'groupPos',
					maxWidth: 50
				}
			)
		if (config.isDisplayClass === true)
			columnsRankingOV.push(
				{
					Header: (
						<Tooltip title={intl.formatMessage({ id: 'TTclass' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'classShort' })}
							</Typography>
						</Tooltip>
					),
					accessor: 'car.classDisplay',
					maxWidth: 50
				},
				{
					Header: (
						<Tooltip title={intl.formatMessage({ id: 'TTclassPos' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'position' })}
							</Typography>
						</Tooltip>
					),
					accessor: 'classPos',
					maxWidth: 50
				}
			)
		if (config.isDisplayCoef === true) {
			columnsRankingOV.push({
				Header: <FormattedMessage id="coef" />,
				accessor: 'car.coefficientDisplay',
				maxWidth: 60,
				Cell: ({ cell }) =>
					<i>
						{cell.value}
					</i>
			})
		}
		if (rankingDay > 1) {
			let previousHeaderCount = ''
			if (rankingDay === 2) {
				previousHeaderCount = ' 1'
			} else {
				previousHeaderCount = ` 1-${rankingDay - 1}`
			}
			columnsRankingOV.push({
				Header: `${intl.formatMessage({ id: msgDay })} ${previousHeaderCount}`,
				id: 'previousDays',
				maxWidth: 100,
				accessor: d => {
					const value = config.isTimeTotal === true ? pointsToTime(d.pointsBefore) : d.pointsBefore
					return value
				},
				Cell: ({ cell }) =>
					<span className={classes.numberSpan}>
						{cell.value}
					</span>
			})
		}
		if (stagesRT.length) {
			for (const [i, value] of stagesRT.entries()) {
				const prefRT = intl.formatMessage({ id: value.name.substring(0, 2).toLowerCase() })
				columnsRankingOV.push({
					Header: <span className={classes.numberSpan}>{`${prefRT}${value.name.substring(2)}`}</span>,
					accessor: 'resultsRT[' + i + ']',
					minWidth: 70,
					maxWidth: 100,
					id: value.id,
					Cell: ({ cell }) =>
						<span className={classes.numberSpan}>
							{cell.value}
						</span>
				})
			}
		}
		if (stagesCHS.length) {
			for (const [i, value] of stagesCHS.entries()) {
				columnsRankingOV.push({
					Header: (
						<span className={classes.numberSpan}>{`${intl.formatMessage({
							id: 'chs'
						})}${value.name.substring(3)}`}</span>
					),
					accessor: 'resultsCHS[' + i + ']',
					width: 80,
					maxWidth: 200,
					id: value.id
				})
			}
		}
		if (config.isCH)
			columnsRankingOV.push({
				Header: (
					<span className={classes.numberSpan}>
						<Tooltip title={intl.formatMessage({ id: 'TTch' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'ch' })}
							</Typography>
						</Tooltip>
					</span>
				),
				accessor: 'totalCH',
				maxWidth: 60,
				Cell: ({ cell }) =>
					<span className={classes.numberSpan}>
						{cell.value}
					</span>
			})
		if (config.isSC)
			columnsRankingOV.push({
				Header: (
					<span className={classes.numberSpan}>
						<Tooltip title={intl.formatMessage({ id: 'TTsc' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'sc' })}
							</Typography>
						</Tooltip>
					</span>
				),
				accessor: 'totalSC',
				maxWidth: 60,
				Cell: ({ cell }) =>
					<span className={classes.numberSpan}>
						{cell.value}
					</span>
			})
		if (config.isCP)
			columnsRankingOV.push({
				Header: (
					<span className={classes.numberSpan}>
						<Tooltip title={intl.formatMessage({ id: 'TTcp' })}>
							<Typography variant="body2" className={classes.typography}>
								{intl.formatMessage({ id: 'cp' })}
							</Typography>
						</Tooltip>
					</span>
				),
				accessor: 'totalCP',
				maxWidth: 60,
				Cell: ({ cell }) =>
					<span className={classes.numberSpan}>
						{cell.value}
					</span>
			})
		columnsRankingOV.push({
			Header: (
				<span className={classes.numberSpan}>
					<Tooltip title={intl.formatMessage({ id: 'TTpen' })}>
						<Typography variant="body2" className={classes.typography}>
							{intl.formatMessage({ id: 'pen' })}
						</Typography>
					</Tooltip>
				</span>
			),
			accessor: 'totalPen',
			maxWidth: 60,
			Cell: ({ cell }) =>
				<span className={classes.numberSpan}>
					{cell.value}
				</span>
		})
		if (rankingDay > 1 || rankingJokers === true || rankingJokersSpecials === true)
			columnsRankingOV.push({
				Header: `${intl.formatMessage({ id: msgDay })} ${rankingDay}`,
				accessor: 'totalPointsDay',
				minWidth: 70,
				maxWidth: 100,
				Cell: ({ cell }) =>
					<span className={classes.numberSpan}>
						{cell.value}
					</span>
			})
		if (rankingJokers === true)
			columnsRankingOV.push({
				Header: <FormattedMessage id="joker" />,
				accessor: 'pointsJokers',
				maxWidth: 60,
				Cell: ({ cell }) =>
					<span className={classes.numberSpan}>
						{cell.value}
					</span>
			})
		if (rankingJokersSpecials === true)
			columnsRankingOV.push({
				Header: config.jokersSpecialsName,
				accessor: 'pointsJokersSpecials',
				maxWidth: 60,
				Cell: ({ cell }) =>
					<span className={classes.numberSpan}>
						{cell.value}
					</span>
			})
	}

	columnsRankingOV.push({
		Header: (
			<span className={classes.numberSpan}>
				<FormattedMessage id="total" />
			</span>
		),
		accessor: d => {
			const value = config.isTimeTotal === true ? pointsToTime(d.totalPointsOV) : d.totalPointsOV
			return value
		},
		id: 'total',
		maxWidth: 50,
		Cell: ({ cell }) =>
			<span className={classes.numberSpan}>
				<strong>
					{cell.value}
				</strong>
			</span>
	})
	if (viewTypeOV === 'detailed') {
		columnsRankingOV.push({
			Header: <FormattedMessage id="difference" />,
			id: 'difPrevious',
			maxWidth: 60,
			accessor: d => {
				const value = config.isTimeTotal === true ? pointsToTime(d.difPrevious) : d.difPrevious
				return value
			}
		})
		columnsRankingOV.push({
			Header: <FormattedMessage id="differenceFirst" />,
			id: 'difFirst',
			maxWidth: 60,
			accessor: d => {
				const value = config.isTimeTotal === true ? pointsToTime(d.difFirst) : d.difFirst
				return value
			}
		})
		if (config.name === 'DKC2021') {
			const rookieLogoPath = `races/${config.name}rookie.png`
			columnsRankingOV.splice(2, 0, {
				Header: <FormattedMessage id="experience" />,
				accessor: 'team.isRookie',
				Cell: ({ cell }) =>
					cell.value === true ? <Image publicId={rookieLogoPath} width="20" height="25" /> : null,
				maxWidth: 25
			})
		}
	}
	const lastDay = config.isPrologue && !config.isPrologueDay1 ? config.days - 1 : config.days

	return (
		<React.Fragment>
			<div className={classes.menu}>
				<MenuOV
					setRankingSelectedOV={setSelectedOV}
					setStagesRT={setStagesRT}
					setStagesCHS={setStagesCHS}
					setRankingDay={setRankingDay}
					setRankingJokers={setRankingJokers}
					setRankingJokersSpecials={setRankingJokersSpecials}
					refetch={refetch}
					rankingDay={rankingDay}
					lastDay={lastDay}
					//onlineStatus={onlineStatus}
				/>
			</div>
			{rankingOV &&
				rankingOV.length > 0 &&
				<React.Fragment>
					<div className={classes.table}>
						<MUITable columns={columnsRankingOV} data={rankingOV} type="rankingOV" isMix={config.isMix} />
					</div>
					<div className={classes.footer}>
						<FooterOV
							viewTypeOV={viewTypeOV}
							setViewTypeOV={setViewTypeOV}
							className="footerOV"
							//style={{ position: 'sticky' }}
						/>
					</div>
				</React.Fragment>}
		</React.Fragment>
	)
}

export default RankingOV

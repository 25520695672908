import React, { useContext } from 'react'
import { Link as RouterLink } from '@reach/router'
import { FormattedMessage, useIntl } from 'react-intl'
import { List, ListItem, ListItemText, ListItemIcon, Link, TextField, Tooltip } from '@material-ui/core'
import { Timer, DriveEta, EmojiEvents, Settings, Schedule, PictureAsPdf } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { OptionsContext } from '../AppContexts'

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 15,
		overflow: 'hidden'
		//border: '1px solid blue'
	},
	icon: {
		color: theme.palette.secondary.main
	},
	carNumber: {
		color: theme.palette.secondary.main
	}
}))

const list = [
	{
		primaryText: <FormattedMessage id="rankingsRT" />,
		path: '/stages',
		icon: <Timer />
	},
	{
		primaryText: <FormattedMessage id="rankingsOV" />,
		path: '/overall',
		icon: <EmojiEvents />
	},
	{
		primaryText: <FormattedMessage id="departures" />,
		path: '/departures',
		icon: <Schedule />
	},
	{
		primaryText: <FormattedMessage id="startList" />,
		path: '/startlist',
		icon: <DriveEta />
	},
	{
		primaryText: 'PDF',
		path: '/pdf',
		icon: <PictureAsPdf />,
		display: true
	}
	/*	{
		primaryText: 'Settings',
		path: '/settings',
		icon: <Settings />
	}*/
]

const NavContent = ({ location, sideBar, setOpen, collapsed }) => {
	const classes = useStyles()
	const intl = useIntl()
	const { carNumber, setCarNumber } = useContext(OptionsContext)
	const pathname = location.pathname === '/' ? '/stages' : location.pathname

	const onBlur = () => {
		setOpen('primarySidebar', false)
	}

	const onKeyDown = e => {
		if (e.key === 'Enter') {
			e.preventDefault()
			e.target.blur()
		}
	}

	const handleChange = event => {
		const value = event.target.value
		if (Number(value) || value === '') {
			const inputValue = value === '' ? value : Number(value)
			setCarNumber(inputValue)
		}
	}

	return (
		<div className={classes.root}>
			<List>
				{list.map(({ primaryText, icon, path }, i) =>
					<Link
						key={i}
						component={RouterLink}
						to={path}
						color="secondary"
						onClick={() => setOpen('primarySidebar', false)}
					>
						<ListItem key={i} selected={pathname === path} button>
							<Tooltip title={primaryText}>
								<ListItemIcon className={classes.icon}>
									{icon}
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary={primaryText} primaryTypographyProps={{ noWrap: true }} />
						</ListItem>
					</Link>
				)}
				{
					<Tooltip title={intl.formatMessage({ id: 'TTcarNumber' })}>
						<TextField
							id="input-car"
							type="tel"
							label="Car#"
							value={carNumber}
							variant="outlined"
							size="small"
							margin="normal"
							autoComplete="off"
							onChange={e => handleChange(e)}
							onBlur={onBlur}
							onKeyDown={onKeyDown}
							color="primary"
						/>
					</Tooltip>
				}
			</List>
		</div>
	)
}

export default NavContent

import React, { useState, useContext, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MenuRT, FooterRT } from '../components/SubMenus'
import { MUITable } from '../components/MUITable'
import { ConfigContext } from '../components/AppContexts'
import { Image } from 'cloudinary-react'

const useStyles = makeStyles(theme => ({
	container: {
		height: '100%'
	},
	menu: {
		height: '64px'
	},
	table: { height: 'calc(100vh - 180px)' },
	footer: {
		height: '40px'
	},
	content: {
		height: 'auto'
	},
	total: {
		//color: theme.palette.primary.main
	},
	numberSpan: {
		display: 'inline-block',
		textAlign: 'right',
		width: '40px'
	}
}))

const RANKING_RT_QUERY = gql`
	query rankingRTQuery($groupID: ID) {
		rankingRT(groupID: $groupID) {
			position
			raceNumber
			teamName
			isRookie
			coefficientDisplay
			model
			avg
			results
			total
			difPrevious
			difFirst
		}
	}
`

const RANKING_RTOV_QUERY = gql`
	query rankingRTOVQuery($groupID: ID) {
		rankingRTOV(groupID: $groupID) {
			position
			raceNumber
			teamName
			coefficientDisplay
			model
			avg
			jokersOV
			totalOV
			difPrevious
			difFirst
		}
	}
`

function RankingRT() {
	const classes = useStyles()
	const config = useContext(ConfigContext)
	const rtPrefix = config.rtPrefix
	const [selectedRT, setSelectedRT] = useState(null)
	const [stages, setStages] = useState([])
	const [rankingRT, setRankingRT] = useState([])
	const [rankingRTOV, setRankingRTOV] = useState([])
	const [viewTypeRT, setViewTypeRT] = useState(localStorage.getItem('viewTypeRT') || 'summary')

	useEffect(
		() => {
			localStorage.setItem('viewTypeRT', viewTypeRT)
		},
		[viewTypeRT]
	)

	const { loading: rankingRTLoading, error: rankingRTError, data: rankingRTData } = useQuery(RANKING_RT_QUERY, {
		skip: !selectedRT,
		pollInterval: config.pollInterval,
		variables: { groupID: selectedRT }
	})
	const {
		loading: rankingRTOVLoading,
		error: rankingRTOVError,
		data: rankingRTOVData
	} = useQuery(RANKING_RTOV_QUERY, {
		skip: !selectedRT || !config.isPartialOV,
		pollInterval: config.pollInterval,
		variables: { groupID: selectedRT }
	})

	useEffect(
		() => {
			if (!rankingRTLoading && rankingRTData) {
				setRankingRT(rankingRTData.rankingRT)
			}
			if (!rankingRTOVLoading && rankingRTOVData) {
				setRankingRTOV(rankingRTOVData.rankingRTOV)
			}
		},
		[rankingRTLoading, rankingRTOVLoading, rankingRTData, rankingRTOVData]
	)
	if (rankingRTLoading || rankingRTOVLoading) return 'Loading...'
	if (rankingRTError || rankingRTOVError)
		return `Error! ${rankingRTError && rankingRTError.message} ${rankingRTOVError && rankingRTOVError.message}`

	const columnsRT = [
		{ Header: <FormattedMessage id="position" />, accessor: 'position', maxWidth: 30, sticky: 'left' },
		{ Header: '#', accessor: 'raceNumber', maxWidth: 30, sticky: 'left' },
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'teamName',
			Cell: ({ cell }) => {
				return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
			},
			minWidth: 175,
			maxWidth: 200
		}
		/*{
			Header: 'Car',
			accessor: 'model',
			minWidth: 170
		}*/
	]

	if (viewTypeRT === 'detailed') {
		if (config.isDisplayCoef === true) {
			columnsRT.push({
				Header: <FormattedMessage id="coef" />,
				accessor: 'coefficientDisplay',
				maxWidth: 50,
				Cell: ({ cell }) =>
					<i>
						{cell.value}
					</i>
			})
		}
		if (stages && stages.length) {
			for (const [i, value] of stages.entries()) {
				const colWidth = value.substring(0, 4) === 'Dif.' || value.substring(0, 2) === 'CL' ? 120 : 60
				columnsRT.push({
					Header: (
						<span className={classes.numberSpan}>
							{value}
						</span>
					),
					accessor: 'results[' + i + ']',
					width: colWidth,
					minWidth: 70,
					maxWidth: 100,
					id: value,
					Cell: ({ cell }) =>
						<span className={classes.numberSpan}>
							{cell.value}
						</span>
				})
			}
		}
	}
	columnsRT.push({
		Header: (
			<span className={classes.numberSpan}>
				<FormattedMessage id="total" />
			</span>
		),
		accessor: 'total',
		maxWidth: 80,
		//sticky: 'right',
		Cell: row =>
			<span className={classes.numberSpan}>
				<strong>
					{row.value}
				</strong>
			</span>
	})
	if (viewTypeRT === 'detailed') {
		columnsRT.push({ Header: <FormattedMessage id="difference" />, accessor: 'difPrevious', maxWidth: 50 })
		columnsRT.push({ Header: <FormattedMessage id="differenceFirst" />, accessor: 'difFirst', maxWidth: 50 })
	}
	const columnsRTOV = [
		{ Header: <FormattedMessage id="position" />, accessor: 'position', maxWidth: 30, sticky: 'left' },
		{ Header: '#', accessor: 'raceNumber', maxWidth: 30, sticky: 'left' },
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'teamName',
			Cell: ({ cell }) => {
				return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
			},
			minWidth: 175,
			maxWidth: 200
		},
		{
			Header: <FormattedMessage id="total" />,
			accessor: d => {
				const total = d.totalOV // - d.jokersOV
				return Number(parseFloat(total).toPrecision(12))
			},
			maxWidth: 50,
			sticky: 'right',
			Cell: row =>
				<strong className={classes.total}>
					{row.value}
				</strong>
		},
		{ Header: <FormattedMessage id="difference" />, accessor: 'difPrevious', maxWidth: 50 },
		{ Header: <FormattedMessage id="differenceFirst" />, accessor: 'difFirst', maxWidth: 50 }
	]

	const rankingRTTable =
		viewTypeRT === 'rankingRTOV'
			? <MUITable columns={columnsRTOV} data={rankingRTOV} type="rankingRT" isMix={config.isMix} />
			: <MUITable columns={columnsRT} data={rankingRT} type="rankingRT" isMix={config.isMix} />

	return (
		<div className={classes.container}>
			<div className={classes.menu}>
				<MenuRT setRankingSelectedRT={setSelectedRT} setRankingStages={setStages} rallyeName={config.name} />
			</div>

			{rankingRT &&
				rankingRT.length > 0 &&
				<React.Fragment>
					<div className={classes.table}>
						{rankingRTTable}
					</div>
					<div className={classes.footer}>
						<FooterRT
							viewTypeRT={viewTypeRT}
							setViewTypeRT={setViewTypeRT}
							className="footerRT"
							rtPrefix={rtPrefix}
							//style={{ position: 'sticky' }}
						/>
					</div>
				</React.Fragment>}
		</div>
	)
}

export default RankingRT

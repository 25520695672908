import React, { useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { CssBaseline } from '@material-ui/core'
import { DriveEta, LocalShipping } from '@material-ui/icons'
import { MUITable } from '../components/MUITable'
import { ConfigContext } from '../components/AppContexts'
import { FormattedMessage } from 'react-intl'
import { Image } from 'cloudinary-react'

const TEAMS_LIST_QUERY = gql`
	{
		teams {
			raceNumber
			teamNameComplete
			race {
				text
			}
			cars {
				model
				year
				capacity
				averages {
					avg
				}
				groupDisplay
				classDisplay
				coefficientDisplay
			}
			vehicle
			isRookie
			racingTeam
		}
	}
`

function StartList() {
	const config = useContext(ConfigContext)
	const { loading, error, data } = useQuery(TEAMS_LIST_QUERY)
	if (loading) return 'Loading...'
	if (error) return `Error! ${error.message}`
	const teams = data.teams

	const columns = [
		{ Header: '#', accessor: 'raceNumber', maxWidth: 35, sticky: 'left' },
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'teamNameComplete',
			Cell: ({ cell }) => {
				//console.log('cell:', cell)
				return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
			},
			minWidth: 220,
			maxWidth: 300
		},
		{
			Header: config.nameCar === 'Vehicle' ? <FormattedMessage id="vehicle" /> : <FormattedMessage id="car" />,
			accessor: 'cars[0].model',
			id: 'car',
			minWidth: 200,
			maxWidth: 220
		},
		{
			Header: <FormattedMessage id="year" />,
			accessor: 'cars[0].year',
			maxWidth: 50
		}
	]
	if (config.isDisplayCapacity === true)
		columns.push({
			Header: <FormattedMessage id="capacity" />,
			accessor: 'cars[0].capacity',
			maxWidth: 50
		})
	if (config.isMultiRaces === true)
		columns.push({
			Header: <FormattedMessage id="race" />,
			accessor: 'race.text',
			maxWidth: 80
		})
	if (config.isDisplayAVG === true)
		columns.push({
			Header: <FormattedMessage id="avg" />,
			accessor: 'cars[0].averages[0].avg'
		})
	if (config.isDisplayGroup === true)
		columns.push({
			Header: <FormattedMessage id="group" />,
			accessor: 'cars[0].groupDisplay',
			maxWidth: 50
		})
	if (config.isDisplayClass === true)
		columns.push({
			Header: <FormattedMessage id="class" />,
			accessor: 'cars[0].classDisplay',
			maxWidth: 50
		})
	if (config.isDisplayCoef === true)
		columns.push({
			Header: <FormattedMessage id="coef" />,
			accessor: 'cars[0].coefficientDisplay',
			maxWidth: 50,
			Cell: ({ cell }) =>
				<i>
					{cell.value}
				</i>
		})
	if (config.isDisplayTeam === true)
		columns.push({
			Header: <FormattedMessage id="team" />,
			accessor: 'racingTeam',
			maxWidth: 150
		})
	if (config.name === 'DKC2021') {
		const rookieLogoPath = `races/${config.name}rookie.png`

		columns.splice(1, 0, {
			Header: <FormattedMessage id="experience" />,
			accessor: 'isRookie',
			Cell: ({ cell }) =>
				cell.value === true ? <Image publicId={rookieLogoPath} width="20" height="25" /> : null,
			maxWidth: 25
		})
		columns.splice(0, 0, {
			Header: () => null,
			accessor: 'vehicle',
			Cell: ({ cell }) =>
				cell.value === 'CAR'
					? <DriveEta style={{ color: '#757575' }} />
					: <LocalShipping style={{ color: '#757575' }} />,
			maxWidth: 30,
			sticky: 'left'
		})
	}

	return (
		<div>
			<CssBaseline />
			<MUITable columns={columns} data={teams} type="startList" />
		</div>
	)
}

export default StartList

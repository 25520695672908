import React, { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { FormattedMessage, useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
//import { makeStyles } from '@material-ui/core/styles'
import { MenuDEP } from '../components/SubMenus'
import { MUITable } from '../components/MUITable'
import { ConfigContext } from '../components/AppContexts'

/*
const useStyles = makeStyles(theme => ({
))
*/

const DEPARTURES_DAY_QUERY = gql`
	query departuresDayQuery($id: ID!) {
		depDay(id: $id) {
			id
			leg {
				name
				num
				dayNum
			}
			times {
				id
				position
				team {
					id
					raceNumber
					teamName
					cars {
						model
						averages {
							day
							avg
						}
						legStart
						legEnd
						groupDisplay
						classDisplay
					}
					race {
						name
					}
				}
				timeStringAM
				timeStringPM
			}
		}
	}
`

function Departures(props) {
	//const classes = useStyles()
	const intl = useIntl()
	const config = useContext(ConfigContext)
	const [depDay, setDepDay] = useState(null)
	const [times, setTimes] = useState([])
	const [dayNum, setDayNum] = useState(null)
	const [legNum, setLegNum] = useState(null)

	const { loading: depDayLoading, error: depDayError, data: depDayData } = useQuery(DEPARTURES_DAY_QUERY, {
		skip: !depDay,
		variables: { id: depDay }
	})

	useEffect(
		() => {
			if (!depDayLoading && depDayData) {
				setTimes(depDayData.depDay.times)
				setDayNum(depDayData.depDay.leg.dayNum)
				setLegNum(depDayData.depDay.leg.num)
			}
		},
		[depDayLoading, depDayData]
	)
	if (depDayLoading) return 'Loading...'
	if (depDayError) return `Error! ${depDayError && depDayError.message}`

	const columnsDepartures = [
		{
			Header: <FormattedMessage id="position" />,
			accessor: 'position',
			maxWidth: 30
		},
		{
			Header: () =>
				config.name === 'DKC2021'
					? <FormattedMessage id="timeAM" values={{ am: 'Bivouac' }} />
					: <FormattedMessage id="timeAM" values={{ am: 'AM' }} />,
			accessor: 'timeStringAM',
			maxWidth: 60
		}
	]

	if (config.isDeparturePM) {
		columnsDepartures.push({
			Header: () =>
				config.name === 'DKC2021'
					? <FormattedMessage id="timePM" values={{ pm: intl.formatMessage({ id: 'firstRZ' }) }} />
					: <FormattedMessage id="timePM" values={{ pm: 'PM' }} />,
			accessor: 'timeStringPM',
			maxWidth: 60
		})
	}

	columnsDepartures.push(
		{ Header: '#', accessor: 'team.raceNumber', id: 'raceNumber', maxWidth: 30 },
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'team.teamName',
			Cell: ({ cell }) => {
				return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
			},
			minWidth: 120,
			maxWidth: 200
		},
		{
			Header: config.nameCar === 'Vehicle' ? <FormattedMessage id="vehicle" /> : <FormattedMessage id="car" />,
			id: 'car',
			accessor: row =>
				row.team && row.team.cars.filter(car => car.legStart <= legNum && car.legEnd >= legNum)[0].model,
			minWidth: 100,
			maxWidth: 120
		}
	)

	if (config.isMultiRaces === true) {
		columnsDepartures.push({
			Header: <FormattedMessage id="race" />,
			accessor: 'team.race.name',
			maxWidth: 80
		})
	}

	if (config.isDisplayAVG === true) {
		columnsDepartures.push({
			Header: <FormattedMessage id="avg" />,
			id: 'avg',
			accessor: row =>
				row.team.cars
					.filter(car => car.legStart <= legNum && car.legEnd >= legNum)[0]
					.averages.filter(avg => avg.day === dayNum)[0].avg,
			maxWidth: 60
		})
	}

	if (config.isDisplayGroup === true) {
		columnsDepartures.push({
			Header: <FormattedMessage id="group" />,
			id: 'group',
			accessor: row =>
				row.team.cars.filter(car => car.legStart <= legNum && car.legEnd >= legNum)[0].groupDisplay,
			maxWidth: 60
		})
	}

	return (
		<React.Fragment>
			<Paper>
				<MenuDEP depDay={depDay} setDepDay={setDepDay} />
			</Paper>
			<Paper>
				{times && times.length > 0 && <MUITable columns={columnsDepartures} data={times} type="departures" />}
			</Paper>
		</React.Fragment>
	)
}

export default Departures

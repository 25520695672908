import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import gql from 'graphql-tag'
import { CloudinaryContext } from 'cloudinary-react'
import './index.css'
import 'fontsource-roboto'
import App from './App'
import { ConfigContext, RacesContext } from './components/AppContexts'
import * as serviceWorker from './serviceWorker'
import dotenv from 'dotenv'
dotenv.config()

//localStorage.clear()
let config = {}
let races = []
const cloudinaryCloudName = 'jbtc'
//console.log('process.env:', process.env)
const raceName = process.env.REACT_APP_RACENAME.replace('jbtclive/', '').toUpperCase()
const uri =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:4100'
		: 'https://' + raceName + '-server-three.vercel.app'
const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: new HttpLink({
		uri: uri
	})
})

const CONFIG_QUERY = gql`
	{
		config {
			isCH
			isSC
			isCHS
			isCP
			name
			nameText
			nameCH
			nameSC
			nameDay
			nameCar
			isMultiRaces
			mixDisplayRaces
			isGroupPos
			isClassPos
			isPrologue
			isPrologueDay1
			isDecimal
			isTimeTotal
			isDisplayRetirements
			isDisplayAVG
			isDisplayGroup
			isDisplayClass
			isDisplayCoef
			isDisplayCapacity
			isDisplayTeam
			rtPrefix
			days
			legs
			raceNameHeader
			primaryColor
			secondaryColor
			pollInterval
			isPartialOV
			timeZone
			isDeparturePM
		}
		races {
			id
			name
		}
	}
`
client
	.query({
		query: CONFIG_QUERY
	})
	.then(async result => {
		config = await result.data.config
		races = await result.data.races
		const favicon = raceName === 'DKC2021' ? raceName + 'fav' : raceName
		document.title = raceName === 'DKC2021' ? 'Dakar Classic 2021' : raceName + ' by JBTimeConcept'
		document.getElementById(
			'faviconApple'
		).href = `https://res.cloudinary.com/${cloudinaryCloudName}/image/upload/c_fill,h_180,w_180,b_rgb:fff,bo_20px_solid_white/races/${favicon}.png`
		document.getElementById(
			'favicon16'
		).href = `https://res.cloudinary.com/${cloudinaryCloudName}/image/upload/c_fill,h_16,w_16/races/${favicon}.png`
		document.getElementById(
			'favicon32'
		).href = `https://res.cloudinary.com/${cloudinaryCloudName}/image/upload/c_fill,h_32,w_32/races/${favicon}.png`
		ReactDOM.render(
			<ApolloProvider client={client}>
				<ConfigContext.Provider value={config}>
					<RacesContext.Provider value={races}>
						<CloudinaryContext cloudName={cloudinaryCloudName}>
							<App />
						</CloudinaryContext>
					</RacesContext.Provider>
				</ConfigContext.Provider>
			</ApolloProvider>,
			document.getElementById('root')
		)
	})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

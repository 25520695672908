import React, { useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { Grid } from '@material-ui/core'
import { ConfigContext } from '../../components/AppContexts'

const useStyles = makeStyles(theme => ({
	root: {
		position: 'static'
	},
	depMenu: {
		padding: 5,
		marginTop: 15,
		width: '100%',
		overflowX: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	},
	depButton: {
		//height: '30px'
		color: theme.palette.secondary.main,
		borderColor: theme.palette.secondary.main,
		borderRadius: '1em'
		//padding: 25
	}
}))

const DEP_LIST_QUERY = gql`
	{
		depList {
			id
			leg {
				dayNum
				name
			}
			times {
				position
			}
		}
	}
`
function MenuDEP({ depDay, setDepDay }) {
	const classes = useStyles()
	const config = useContext(ConfigContext)
	const intl = useIntl()
	let msgDay
	switch (config.nameDay) {
		case 'Day':
			msgDay = 'day'
			break
		case 'Leg':
			msgDay = 'leg'
			break
		case 'Stage':
			msgDay = 'stage'
			break
		default:
			msgDay = 'day'
			break
	}
	//const [selectedDay, setSelectedDay] = useState(null)

	let depList = []
	const { loading: depListLoading, error: depListError, data: depListData } = useQuery(DEP_LIST_QUERY)

	if (depListLoading) return 'Loading...'
	if (depListError) return `Error! ${depListError && depListError.message}`
	if (depListData) depList = depListData.depList
	//	if (selectedDay === null) setSelectedDay(depList[0].id)

	const handleChange = value => {
		setDepDay(value)
	}

	return (
		<React.Fragment>
			<ToggleButtonGroup
				variant="outlined"
				size="small"
				exclusive={true}
				onChange={(event, value) => {
					if (value) {
						handleChange(value)
					}
				}}
				value={depDay}
				className={classes.depMenu}
			>
				{depList.map(dep => {
					const depID = dep.id
					return (
						<ToggleButton
							key={depID}
							value={depID}
							disabled={!dep.times.length}
							className={classes.depButton}
						>
							{dep.leg.name.replace(msgDay.toUpperCase(), intl.formatMessage({ id: msgDay }))}
						</ToggleButton>
					)
				})}
			</ToggleButtonGroup>
		</React.Fragment>
	)
}
export default MenuDEP

import { LOCALES } from '../locales'

export default {
	[LOCALES.ARABIC]: {
		//MENU
		rankingsRT: 'مناطق السرعة المحدّدة',
		rankingsOV: 'الترتيب العام',
		departures: 'توقيت الانطلاق',
		startList: 'قائمة المنطلقين',
		//COLUMNS
		position: 'المركز',
		experience: 'التجربة',
		crew: 'الطاقم',
		car: 'Car',
		vehicle: 'المركبة',
		total: 'المجموع ',
		difference: 'الفارق',
		differenceFirst: 'الفارق مع المتصدّر',
		year: 'العام',
		groupShort: 'Gr',
		classShort: 'Cl',
		group: 'المجموعة',
		class: 'الفئة',
		capacity: 'Capacity',
		race: 'Race',
		avg: 'AVG',
		coef: 'Coef',
		team: 'Team',
		rt: 'RT',
		rz: 'RZ',
		ch: 'TC',
		sc: 'SC',
		cp: 'CP',
		chs: 'TCS',
		pen: 'العقوبات',
		joker: 'جوكر',
		timeAM: 'الانطلاق من معسكر المبيت',
		timePM: 'الانطلاق من منطقة السرعة المحدّدة الأولى',
		firstRZ: 'KIKOO',
		leg: 'Leg',
		day: 'Day',
		stage: 'المرحلة',
		//BOUTONS
		rtSummary: 'RT Summary',
		rtDetailed: 'RT Detailed',
		rzSummary: 'ملخص مناطق السرعة المحدّدة',
		rzDetailed: 'تفاصيل مناطق السرعة المحدّدة',
		ovSummary: 'OV Summary',
		ovDetailed: 'OV Detailed',
		gcSummary: 'ملخص الترتيب العام',
		gcDetailed: 'تفاصيل الترتيب العام',
		//TOOLTIPS
		TTcarNumber: 'ضع عدد الطاقم لتسليط الضوء عليه',
		TTgroup: 'المجموعة',
		TTgroupPos: 'Group position',
		TTclass: 'الفئة',
		TTclassPos: 'Class position',
		TTch: 'مراقبة التوقيت',
		TTsc: 'مراقبة السرعة',
		TTcp: 'مراقبة المرور',
		TTpen: 'Other Penalties',
		//MISC
		canceled: 'CANCELED'
	}
}
